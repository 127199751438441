export const columns = [
  {
    title: 'LOGO',
    dataIndex: 'logo',
    key: 'logo',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'logo'}
  },{
    title: '公司名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center'
},
{
    title: '公司编码',
    dataIndex: 'code',
    key: 'code',
    /*width:150,*/
    align:'center'
},
{
    title: '是否启用',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'flagEnable'}
},
{
    title: '类型',
    dataIndex: 'queryType',
    key: 'queryType',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'queryType'}
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center'
},
{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
